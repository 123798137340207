/* REQUIRED */
@import '~pretty-checkbox/src/scss/variables';
// If you felt the name is not-so-pretty,
// you can always change!

$pretty--class-name: pretty;

// are you sure, you wanna change my handpicked
// awesome super duper colors?

$pretty--color-default: #bdc3c7;
$pretty--color-primary: #428bca;
$pretty--color-info: #5bc0de;
$pretty--color-success: #5cb85c;
$pretty--color-warning: #f0ad4e;
$pretty--color-danger: #d9534f;
$pretty--color-dark: #5a656b;

// uh, boring z-index stuff, who cares.

$pretty--z-index-back: 0;
$pretty--z-index-between: 1;
$pretty--z-index-front: 2;

// nobody will change this.

$pretty--debug: false;
$pretty--dev-err: 'Invalid input type';


@import '~pretty-checkbox/src/scss/core';

/* OPTIONALS */
@import '~pretty-checkbox/src/scss/elements/default/fill';
@import '~pretty-checkbox/src/scss/elements/default/outline';
@import '~pretty-checkbox/src/scss/elements/default/thick';

@import '~pretty-checkbox/src/scss/elements/font-icon/general';

@import '~pretty-checkbox/src/scss/elements/svg/general';

@import '~pretty-checkbox/src/scss/elements/image/general';

@import '~pretty-checkbox/src/scss/elements/switch/general';
@import '~pretty-checkbox/src/scss/elements/switch/fill';
@import '~pretty-checkbox/src/scss/elements/switch/slim';

@import '~pretty-checkbox/src/scss/extras/toggle';
@import '~pretty-checkbox/src/scss/extras/plain';
@import '~pretty-checkbox/src/scss/extras/round';
@import '~pretty-checkbox/src/scss/extras/curve';
@import '~pretty-checkbox/src/scss/extras/animation';
@import '~pretty-checkbox/src/scss/extras/disabled';
@import '~pretty-checkbox/src/scss/extras/locked';
@import '~pretty-checkbox/src/scss/extras/colors';
@import '~pretty-checkbox/src/scss/extras/print';

@import '~pretty-checkbox/src/scss/states/hover';
@import '~pretty-checkbox/src/scss/states/focus';
@import '~pretty-checkbox/src/scss/states/indeterminate';


#projectDetail input[type='text'], #projectDetail input[type='password'] {border: 0px;border-radius: 4px;box-shadow: none;padding: 10px 18px;height: 47px;margin: 0px 0px 10px;width:100%;}

#projectDetail .chart {    margin: 20px 20px 0px 0px;}

#projectDetail .intl-tel-input {width:100%;    margin-bottom: 8px;}
#projectDetail .intl-tel-input input {border: 0px;border-radius: 4px;box-shadow: none;height: 47px;margin: 0px 0px 10px;padding-left: 60px;width:100%;}
#projectDetail .intl-tel-input.allow-dropdown .flag-container {left:0;}
#projectDetail .intl-tel-input.allow-dropdown .selected-flag {outline:0;padding-left: 12px;width:52px;}
#projectDetail .intl-tel-input .country-list {top: 44px;left: 2px;border-top: 0px;border-color: #f2f2f2;box-shadow: 1px 7px 6px rgba(0,0,0,.1);width:100%;}
#projectDetail .intl-tel-input.allow-dropdown input, .intl-tel-input.allow-dropdown input[type=tel], .intl-tel-input.allow-dropdown input[type=text] {padding-left: 58px;}
#projectDetail .intl-tel-input .country-list {overflow-x: hidden;overflow-y: auto;}
#projectDetail .intl-tel-input .country-list .country-name, .intl-tel-input .country-list .flag-box {    font-size: 13px;}
#projectDetail .intl-tel-input .country-list .country .dial-code {font-size:13px;}
#projectDetail .autocomplete-dropdown-container {    position: absolute;top: 46px;z-index:4;width: 100%;background: #FFF;border-bottom-left-radius: 4px;border-bottom-right-radius: 4px;}
#projectDetail .autocomplete-dropdown-container > div {padding: 10px;}

#projectDetail  [class$="-menu"] { position: relative; z-index: 1000; }
#projectDetail .input {position:relative;}

#sidePanel #projectDetail {padding:11px 10px 10px 17px;height:100%;}
.react-tabs__tab {font-size: 12px;}
.react-tabs__tab--selected {border-color:#ddd}
.react-tabs__tab-list {border-bottom-color:#DDD}
#sidePanel #projectDetail input[type='text'],#sidePanel #projectDetail input[type='number'],#sidePanel #projectDetail textarea, #sidePanel #projectDetail input[type='password'], #sidePanel #projectDetail .intl-tel-input input{height:18px;border: solid 1px #e2e2e2;}
#sidePanel #projectDetail input.textInput {height:40px;padding: 10px;    width: 100%;}
#sidePanel #projectDetail textarea {    width: 100%;padding: 8px 10px;min-height: 90px;}
#sidePanel #projectDetail .autocomplete-dropdown-container {    border: solid 1px #E2e2e2;border-top: 0px;}
#sidePanel #projectDetail .react-tabs {height: 100%;    margin-top: -82px;padding-top: 82px;}
#sidePanel #projectDetail .footer {    border-top: solid 1px #EEE;margin:0px;    background: #FFF;z-index: 999999;    position: absolute;right: 1px;width: 100%;padding: 8px 20px 9px;bottom: 0px;}
#sidePanel #projectDetail .container {overflow: auto;height:100%;    padding-bottom: 30px;}
#sidePanel #projectDetail .footer .pretty {    margin:6px 18px;font-size: 14px;}
#sidePanel #projectDetail .footer .pretty .state label:before {margin-right:10px;    margin-left: -8px;}
#sidePanel #projectDetail .footer .pretty .state label:after {margin-right:10px;    margin-left: -8px;}


#sidePanel .progress {margin-right: 20px;}
#sidePanel .progress .count {    font-size: 16px;line-height: 16px;margin:10px 2px -4px;font-weight: 600;color: #444;position: relative;}
#sidePanel .progress .count small {font-size: 12px;margin-left: 8px;color: #999;    position: absolute;right: 0px;top: 4px;}
#sidePanel .progress p {font-size: 12px;margin: 0px;color: #666;}

#projectDetail h1 {font-size:20px;margin:2px 0px 0px;}
#projectDetail h2 {font-weight: 300;margin: 10px 0px 10px 0px;font-size: 14px;color: #444;}
#projectDetail .field > div > .title {font-weight:700;font-size:14px;position: sticky;margin:16px 0px 0px}
#projectDetail .field .prefix {    font-size: 16px;margin: 9px 0px 0px 12px;text-align: right;}
#projectDetail .field {    border-top: solid 1px #e9e9e9;margin-top: 4px;}
#projectDetail .field .col.passive {display:none;}
#projectDetail .field .title {font-size:14px;margin-top:8px;color:#555;font-weight:700;    margin-bottom: 6px;}
#projectDetail .field .description {margin:0px 0px 10px;Font-size:12px;color:#999;height:32px}
#projectDetail .field .description:first-child {margin-top:10px}
#projectDetail .field .input {    margin-bottom: 8px;margin-top: 4px;}
#projectDetail .field .input [type='file'] {width:102px}
#projectDetail .field > div .input:first-child {margin-top:14px}
#projectDetail .group .field > div > .title {font-size: 14px;margin-top: 8px;color: #555;font-weight: 700;margin-bottom: 6px;background: #eee;padding: 10px 14px;}
#projectDetail .columnList {display:none;}
#projectDetail .columnList.active {display:flex;}

#dashboard .activity .block ul li .icon {background: #CCC;width: 21px;height: 21px;padding: 5px;border-radius: 50%;margin-top: 5px;position:relative;z-index:2;color: #777;left:3px;}
#dashboard .activity .block ul li .icon.active {background:#45bb58}
#dashboard .activity .block .line {left:54px;    height: calc(100% - 180px);}
