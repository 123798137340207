@import '~@csstools/normalize.css';
textarea:focus, input:focus{
  outline: none;
}

@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  src: local('Lato'), local('Lato-Regular'), url(/assets/fonts/Lato/Lato-Regular.ttf) format('truetype');
}
body * {font-family:"Lato"}


.row {    max-width: 78rem;}

a {    background-color: transparent;color: #4085c7;text-decoration: none;cursor: pointer;    font-size: 12px;transition: all .2s ease;}
a b {color: #4085c7 !important;transition: all .2s ease;}
a:hover {    color: #12566b;}
a:hover b {color: #12566b !important;;}

.column.noPaddingLeft,.columns.noPaddingLeft {padding-left:0px;}
.column.noPaddingRight,.columns.noPaddingRight {padding-right:0px;}
.columns.noPadding,.column.noPadding {padding:0px;}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  min-height:100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #09d3ac;
}

.transition {transition: all .2s ease;}

.box {
  background-color: #f7f7f7;
  border-radius: 4px;
  border: solid 1px #f4f4f4;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1)
}

ul.pagination {
  display: inline-block;
  padding-left: 15px;
  padding-right: 15px;
}

ul.pagination li {display: inline-block;border: solid 1px #EEE;margin:10px 0px 8px;    background: #FFF;}
ul.pagination li a {display:block;width:42px;outline:0;font-size: 14px;font-weight: 700;padding: 8px 12px 10px;cursor:pointer;transition: all .2s ease;text-align: center;}
ul.pagination li a:hover {background-color:#f2f2f2}
ul.pagination li.active {}
ul.pagination li.active a {background: #777 !important; color:#FFF !important;cursor:default !important;}
ul.pagination li:first-child {border-top-left-radius: 8px;border-bottom-left-radius: 8px;}
ul.pagination li:last-child {border-top-right-radius: 8px;border-bottom-right-radius: 8px;}
ul.pagination li:first-child svg, ul.pagination li:last-child svg {    position: relative;top: 1px;}
.tether-element {z-index:1001 !important;}
.tether-element > div {
  display: inline-block;
  opacity: 0.8;
  position: relative;
  border-radius: 6px;
  background: rgb(51, 51, 51);
  padding: 4px 8px;
  max-width:250px;
}
.tether-element-attached-left > div::after{
  top: calc(50% - 6px);
  left: -6px;
  position: absolute;
  content: " ";
  font-size: 0px;
  line-height: 0;
  width: 0px;
  border-top: 6px solid transparent;
  border-bottom: 6px solid transparent;
  border-right: 6px solid rgb(51, 51, 51);
}
.tether-element-attached-right > div::after {
  top: calc(50% - 8px);
  left: -8px;
  position: absolute;
  content: " ";
  font-size: 0px;
  line-height: 0;
  width: 0px;
  border-top: 8px solid transparent;
  border-bottom: 8px solid transparent;
  border-right: 8px solid rgb(51, 51, 51);
}
.tether-element-attached-bottom > div::after {
  bottom: -6px;
  left: calc(50% - 6px);
  position: absolute;
  content: " ";
  font-size: 0px;
  line-height: 0;
  width: 0px;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-top: 6px solid rgb(51, 51, 51);
}
.tether-element-attached-top > div::after {
  top: -6px;
  left: calc(50% - 6px);
  position: absolute;
  content: " ";
  font-size: 0px;
  line-height: 0;
  width: 0px;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-bottom: 6px solid rgb(51, 51, 51);
}


.button {
  font-size: 14px;
  border: 1px solid #4085c7;
  border-radius: 2px;
  padding: 5px 16px 6px;
  background-color: transparent;
  color: #2ba6cb;
  cursor:pointer;
  transition: all .2s ease;
}
.button.small {    font-size: 12px;padding: 4px 10px 4px;margin: 5px 0px;display: inline-block;}
.button.ng {background-color:#4085c7;color:#FFF;}
.button:hover {
  background-color: #4085c7;
  color: #FFF;
}
.button.disabled {cursor:default;border-color:#CCC;color:#666;background-color:#CCC}

.button .inputSpinner {display: inline-block;position: relative;top: 3px;margin-left: 12px;}
.button.loading {padding-right: 7px;}

.button.green {border-color:#60a704;color:#60a704}
.button.green svg {color:#60a704}
.button.green:hover {background-color:#60a704;color:#FFF}
.button.yellow {border-color:#a7a71e;}
.button.yellow svg {color:#a7a71e}
.button.yellow:hover {background-color:#a7a71e;}
.button.red {border-color:#960c0c;color:#960c0c}
.button.red svg {color:#960c0c}
.button.red:hover {background-color:#960c0c;color:#FFF;}
.button.yellow:hover svg,.button.red:hover svg {color:#FFF}

.button.ng:hover {background-color:#17728e;border-color:#17728e}
.button.icon {padding: 2px 5px 3px;border-color: #7bb5ec;    border-radius: 4px;overflow: hidden;}
.button.icon svg {font-size: 14px;margin: 2px 0px 0px;width: 16px;padding: 0px;}
.button.icon {}

.tipBoxWrapper {color:#FFF;text-align:center;font-size:13px;margin:0;padding:4px 0px}
.tether-element-attached-bottom {top:-5px !important;}
.tipBoxWrapper.small {font-size:10px;padding:1px 0px;}

.arrowBox {    position: relative;background: #fff;border: 1px solid #2BA6CB;border-radius: 4px;    z-index: 2;}
.arrowBox:after, .arrowBox:before {
  bottom: 100%;
  left: 50%;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
}
.arrowBox:before {
  border-color: rgba(194,225,245,0);
  border-bottom-color: #2BA6CB;
  border-width: 8px;
  margin-left: -8px;
}
.ds {
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.stickyWrapper {position:relative;height:100%;}
.stickyWrapper > div, .stickyWrapper > div > div {height:100%;}

span.type0 svg {background-color:#d9e425 !important;}
span.type1 svg {background-color:yellowgreen !important;}
span.type2 svg {background-color:indianred !important;}
span.type3 svg {background-color:cornflowerblue !important;}
span.type4 svg {background-color:mediumpurple !important;}
.passive span[class^="type"] svg {background-color:#CCC !important;}

.react-confirm-alert-overlay {z-index:9999 !important;background: rgba(255, 255, 255, 0.6) !important;}
.react-confirm-alert-body {    border: solid 1px #DDD !important;;padding: 10px 20px 15px !important;;box-shadow:none !important;    font-size: 14px !important; }
.react-confirm-alert-body > h1 {    font-size: 18px !important;;margin-top: 2px !important;    margin-bottom: 0px !important;}
.react-confirm-alert-button-group > button {    font-size: 14px !important;;padding: 5px 16px 5px !important;background-color:#4085c7 !important;}
.react-confirm-alert-button-group > button.cancel {background-color:darkred !important;}

