.bg {height: 100%;position: fixed;width: 100%;background-image: url(/assets/images/bg.jpg);background-size: cover;background-position: 50% 50%;}
.bg > div {height:100%;background: rgba(255, 255, 255, 0.9);}
.bg > div > div {height:100%;}

#auth img.logo {    height: 30px;margin-bottom: 16px;margin-top:-30px}
#auth form {height:200px}
#auth form input[type='text'], form input[type='password'] {
  border: 0px;
  border-radius: 4px;
  box-shadow: none;
  padding: 10px 18px;
  height: 47px;
  margin: 0px 0px 10px;
}
#auth .intl-tel-input input {border: 0px;border-radius: 4px;box-shadow: none;height: 47px;margin: 0px 0px 10px;padding-left: 60px;}
#auth .intl-tel-input.allow-dropdown .flag-container {left:0;}
#auth .intl-tel-input.allow-dropdown .selected-flag {outline:0;padding-left: 12px;width:52px;}
#auth .intl-tel-input .country-list {top: 47px;left: 0px;border-top: 0px;border-color: #f2f2f2;box-shadow: 1px 7px 6px rgba(0,0,0,.1);}
#auth .intl-tel-input.allow-dropdown input, .intl-tel-input.allow-dropdown input[type=tel], .intl-tel-input.allow-dropdown input[type=text] {padding-left: 58px;}
#auth .intl-tel-input .country-list {overflow-x: hidden;overflow-y: auto;}
#auth .intl-tel-input .country-list .country-name, .intl-tel-input .country-list .flag-box {    font-size: 13px;}
#auth .intl-tel-input .country-list .country .dial-code {font-size:13px;}
#auth .infoText {    margin: 4px 0px 5px;font-size: 13px;font-weight: 400;color: #222;}
#auth .infoText.alt {color:#777}
#auth .buttons {padding-top: 12px;}
#auth .inputSpinner {    position: absolute;right: 44px;top: 24px;}
#auth .field {}
#auth .field .title {font-size:14px;color:#555;margin-bottom:6px;}
#auth .field .input {}
