#popForm ul {margin:0px;padding:0px}

#popForm .button.full {    width: 100%;display: block;margin-bottom: 12px;border-radius: 6px;    margin-top: 6px;}
#popForm .button.full:hover {}

#popForm {width: 450px;padding:0px 30px;}
#popForm > div {box-shadow: 1px 4px 6px -6px;}
#popForm .title {    margin: 20px 20px 26px;font-size: 20px;font-weight: 600;color: #666;}
#popForm .stepper > div > div > div > div span {}
#popForm .inputWrapper {    padding: 20px 36px;}
#popForm .buttons {    margin-top: 20px;margin-bottom:10px}
#popForm .buttons .button {    margin: 0px 10px;}
#popForm .input {height: 40px;border: solid 1px #CCC; padding: 4px 10px;color: #000;width: 100%;border-radius: 4px;margin-top: 8px; }
#popForm .inputLabel {font-size:14px;margin-top:10px;font-weight:700}
#popForm .resume {text-align:left;}
#popForm .resume .stepName {font-weight:600;}
#popForm .resume .stepValue {margin-bottom:10px;font-size:14px;}
#popForm [class$="singleValue"] {width:100%;text-align:left}
#popForm [class$="menu"] {text-align:left;}

#popForm .intl-tel-input {width:100%;}
#popForm .intl-tel-input.allow-dropdown input {width: 100%;padding: 12px 5px 12px 50px;border: solid 1px #CCC;box-shadow: none;outline: 0px;border-radius: 4px;}

#popForm.assignToUser .list {    overflow: auto;height: 236px;}
#popForm.assignToUser .list .title {margin:6px 20px 4px 5px}
#popForm.assignToUser .list {margin:0px;padding:0px;}
#popForm.assignToUser .list li.user {    padding: 10px 0px;border-top: solid 1px #EEE;}
#popForm.assignToUser .list li.user a {display:block ;    text-align: left;}
#popForm.assignToUser .list li.user:first-child {border-top:0px;}
#popForm.assignToUser .list li.user b {font-size:14px;color:#555}
#popForm.assignToUser .list li.user .name {}
#popForm.assignToUser .list li.user .name img {border-radius:50%;display:inherit;position:relative;transform:scale(1);    width: 30px;height: 30px;margin:4px 10px 0px 0px;}
#popForm.assignToUser .list li.user .name img:hover {transform:scale(3);box-shadow: 0 2px 4px rgba(0,0,0,0.1);}
#popForm.assignToUser .list li.user .name b {display:block;}
#popForm.assignToUser .list li.user .name small {display:block;color:#999;    line-height: 14px;}
#popForm.assignToUser .list li.user .activities {font-size:12px;font-weight:700}
#popForm.assignToUser .list li.user .activities > div {line-height:initial}
#popForm.assignToUser .list li.user .activities small {font-weight:400;color:#666}
#popForm.assignToUser .list li.user .switch {    margin-top: 9px;    margin-right: 20px;}

#popNotes {max-width:660px}
#popNotes .popTitle {text-align:center;padding:10px 0px;display: block;border-bottom: solid 1px #EEE;color: #666;}
#popNotes .comments {width:401px;height:282px;overflow:auto}
#popNotes .comment {text-align:left;border-top: solid 1px #EEE;}
#popNotes .comment:first-child {border-top:0px}
#popNotes > div > div > div > div > div > div > div.columns.shrink {padding-right:0px}
#popNotes > div > div:first-child + div {    border-right: solid 1px #EEE;}
#popNotes .comment > div{padding: 15px 10px;}
#popNotes .comment .profileImg {    width: 40px;margin-left: 10px;border-radius: 50%;border: 2px solid #FFF;background-color: #ddd;}
#popNotes .comment .fullName {    font-size: 12px;font-weight: 700;margin-top: 2px;}
#popNotes .comment .title {font-size:10px}
#popNotes .comment .created {    font-size: 12px;color: #999;}
#popNotes .inputWrapper {       padding: 14px 20px 10px 0px;;text-align:left;}
#popNotes .buttons {    margin-top: 20px;margin-bottom:10px}
#popNotes .buttons .button {    margin: 0px 0px;}
#popNotes .input {height: 100px;border: solid 1px #CCC; padding: 4px 10px;color: #000;width: 100%;border-radius: 4px;margin-top: 8px;}
#popNotes .inputLabel {font-size:14px;margin-top:10px;font-weight:700}
#popNotes .inputWrapper > .inputLabel:first-child {margin-top:0px}
