body {    background-color: #f6f6f6;}

#panel img.logo {    height: 26px;margin-left: 32px;margin-top: 3px;}
#panel a.dev {padding-top:4px;}
#panel a.dev img {    height: 18px;    margin-right: 2px;}
#panel > .header {    margin: 15px 0px 2px;}


.sb-avatar {    margin: 2px 10px 0px 0px;}
.sb-avatar img {margin: 0px !important;transition: all .2s ease-out 0s;z-index:2 }
.sb-avatar img:hover {z-index:3}


#panelName {font-size: 16px;line-height:initial;color: #666;text-align: left;margin-left: -14px;margin-top:1px}
#panelName small {font-size:12px;color:#999;    position: relative;top: -4px;}


#searchBox {text-align:left;position:relative}
#searchBox > div > input {border: 0px;background: #FFF;    padding: 10px 16px 11px 40px;width: 60%;min-width: 400px;margin-top: 0px;border: solid 1px transparent;border-bottom: 0px;}
#searchBox svg {position:absolute;left: 12px;top: 11px;}
#searchBox #react-autowhatever-1 {    position: absolute;z-index: 1003;background: #FFF;width: 60%;min-width: 400px;box-shadow: 0px 1px 0px 0px rgba(0, 0, 0, 0.1);border: solid 1px #f2f2f2;border-top: 0px;}
#searchBox #react-autowhatever-1 ul {margin:0px;list-style:none;padding:0px;}
#searchBox #react-autowhatever-1 ul li {border-top:solid 1px #f2f2f2;    padding: 6px 12px;cursor:pointer;}
#searchBox .react-autosuggest__suggestion--highlighted {background-color:#EEE;}
#searchBox #react-autowhatever-1 ul li:hover {background-color:#666 !important;color:#FFF !important;}
#searchBox .react-autosuggest__input--open {border-color:#f2f2f2}

#toastContainer {    position: absolute;top: 0px;right: 10px;width: 600px;background: transparent;border: 0px;    z-index: 1000;}

#sidePanel {    position: absolute;right: 0px;top: 0px;width:100%;height:100%;z-index: 1002;    background: rgba(0, 0, 0, 0);visibility:hidden;opacity:0;    transition: all .2s ease-out 0s;border-radius: 6px;border-top-right-radius: 0px;border-bottom-right-radius: 0px;}
#sidePanel .outTrigger {position:absolute;left:0px;top:0px;width:100%;height:100%;}
#sidePanel.open {background: rgba(0, 0, 0, 0.1);visibility:visible;opacity:1}
#sidePanel > div {position:relative;height: 100%;    width: 800px;}
#sidePanel > div > div + div {background-color:#FFF;position:absolute;top:0px;width:800px;height:100%;border-radius:14px;border-bottom-right-radius:0px;border-top-right-radius:0px;overflow:hidden;transform: translateX(100%) !important;animation-delay: 2s;box-shadow:-1px 0px 0px 0px rgba(0, 0, 0, 0.1);text-align:left;}
#sidePanel.open > div > div + div {transform: translateX(0%) !important;}
#sidePanel.open > div > div + div > div > div {height:100%;}
#sidePanel .wrapper {position:relative}
#sidePanel .header {    margin-bottom: 12px;padding-bottom: 8px;height:32px;}
#sidePanel .header .close {    font-size: 24px;color: #999;cursor:pointer;}
#sidePanel .header .close:hover {color:#666;}
#sidePanel .header .logo {    width: 30px;}
#sidePanel .header .title {font-size: 18px;margin-top: 1px;font-weight: 600;color: #666;}
#sidePanel .header .title b {display:block;line-height:initial;margin-top: -7px;}
#sidePanel .header .title small {    font-size: 12px;display:block;}
#sidePanel .footer .button.component {    position: relative;top: 7px;}
#sidePanel .closeButton {padding:0px !important;}
#sidePanel .closeButton a {    display: block;text-align: center;width: 44px;height: 40px;border-right: solid 1px #EEE;padding:10px 0px 12px 2px;}
#sidePanel .closeButton a:hover {background-color:#666;color:#FFF;cursor:pointer}
#sidePanel .closeButton svg {    font-size: 22px;}
#sidePanel .stepper {margin-top:30px;    margin-left: -4px;}
#sidePanel .stepper a,#sidePanel .stepper span {font-size:22px;line-height:24px !important;}

#sidePanel .templateSelection {    width: 180px;    margin-top: -5px;}
#sidePanel .bankSelection {width:100%;margin-top: -5px;}
#sidePanel .templateSelection > div > div > div + div,
#sidePanel .bankSelection > div > div > div + div {margin:-4px 0px}

#popUp {position: absolute;right: 0px;top: 0px;width:100%;height:100%;z-index: 1002;    background: rgba(0, 0, 0, 0);visibility:hidden;opacity:0;    transition: all .2s ease-out 0s;border-radius: 6px;border-top-right-radius: 0px;border-bottom-right-radius: 0px;}
#popUp .outTrigger {position:absolute;left:0px;top:0px;width:100%;height:100%;    background: transparent;margin: 0px;z-index:0}
#popUp.open {background: rgba(0, 0, 0, 0.1);visibility:visible;opacity:1}
#popUp > div {position:fixed;display: block;margin: 0px auto;    width: 1031px;}
#popUp > div > div {background: rgba(255, 255, 255, 0.95);margin-top: 50px;    border-radius: 8px;    position: relative;top: 10%;    display: inline-block;z-index:1}
#popUp > div > div + div {background-color:#FFF;position:relative;top:0px;width:auto;height:auto;border-radius:14px;border-bottom-right-radius:0px;border-top-right-radius:0px;overflow:hidden;transform: translateX(100%) !important;animation-delay: 2s;box-shadow:-1px 0px 0px 0px rgba(0, 0, 0, 0.1);text-align:left;}
#popUp.open > div > div + div {transform: translateX(0%) !important;}
#popUp .wrapper {position:relative}
#popUp .header {    border-bottom: solid 1px #EEE;}
#popUp .header > div > div  {padding-top: 5px;padding-bottom: 3px;}
#popUp .header h1 {margin:0px;font-size:20px;    font-weight: 100;}
#popUp .header .code {font-size:16px;line-height: initial;}
#popUp .header .type {font-size:10px;color:#666;line-height: initial;}
#popUp .closeButton {padding:0px !important;}
#popUp .closeButton a {    display: block;text-align: center;width: 44px;height: 40px;border-right: solid 1px #EEE;padding:10px 0px 12px 2px;}
#popUp .closeButton a:hover {background-color:#666;color:#FFF;cursor:pointer}
#popUp .closeButton svg {    font-size: 22px;}
#popUp .stepper {margin-top:30px;    margin-left: -4px;}
#popUp .stepper a,#popUp .stepper span {font-size:22px;line-height:24px !important;}

.switch.small {    height: 20px !important;width: 40px !important;;}
.switch.small > span {width: 17px !important;;height: 17px !important;;}

#profile {}
#profile .fullName {    font-size: 12px;font-weight:700;text-align:right;    margin-top: 2px;}
#profile .title {    font-size: 10px;text-align:right;}
#profile .profileImg {    width: 40px;margin-left: 10px;border-radius: 50%;border: 2px solid #FFF;background-color: #ddd;}

#profile svg {color:#999;}
#profile .columns:hover svg {color:#666}

#panel > .header .notifications {position:relative;}
#panel > .header .notifications > svg {    margin: 9px;}
#panel > .header .notifications .dropDown {visibility:hidden;opacity:0;position: absolute;right: -10px;top: 46px;z-index:1001}
#panel > .header .notifications:hover .dropDown {visibility:visible;opacity:1}
#panel > .header .notifications .dropDown > div {width: 230px;}
#panel > .header .notifications .dropDown > div:before {margin-left: 78px;}
#panel > .header .notifications .showAll {    display: block;border-top: solid 1px #eee;text-align: center;font-size: 12px;padding: 6px;border-bottom-left-radius: 4px;border-bottom-right-radius: 4px;font-weight: 700;}
#panel > .header .notifications .showAll:hover {    background-color: #2ba6cb;color: #FFF;border-top-color:#2ba6cb}
#panel > .header .notifications .list ul {list-style:none;margin:0px;padding:0px;text-align:left;}
#panel > .header .notifications .list > div:before {    margin-left: 79px;}
#panel > .header .notifications .list > div:after {margin-left: 80px;}
#panel > .header .notifications .list ul li {float:none;    border-top: solid 1px #EEE;cursor:pointer;width: 100%;}
#panel > .header .notifications .list ul li:first-child {border-top:0px;border-top-left-radius: 4px;border-top-right-radius: 4px;}
#panel > .header .notifications .list ul li:hover {background-color:#f6f6f6;}
#panel > .header .notifications .list ul li > a {display:block;padding:10px 10px 0px;}
#panel > .header .notifications .list ul li .img {}
#panel > .header .notifications .list ul li .img img {width:30px;height:30px;border-radius:0px;    border: solid 1px #CCC;}
#panel > .header .notifications .list ul li.loading {    width: 100%;text-align: center;padding-bottom: 7px;background-color:#FFF;cursor:default;}
#panel > .header .notifications .list ul li.loading img {width:20px;}
#panel > .header .notifications .list ul li .detail {}
#panel > .header .notifications .list ul li .detail p {margin:0px;    font-size: 12px;    display: block;    font-weight: 100;line-height: 16px;margin-bottom: 4px;}
#panel > .header .notifications .list ul li .detail .date {    display: block;line-height: initial;padding: 3px 0px 10px;font-size: 11px;border-top: solid 1px #eee;margin-top: 2px;color: #666;    font-weight: 100;}
#panel > .header .notifications .list ul li .detail svg {margin-right:4px}
#panel > .header .logOut {display:block;}
#panel > .header .logOut svg {margin:7px 2px;}

#panel .footer {margin-bottom:18px}
#panel .footer .info {padding-top: 10px;text-align:left;}
#panel .footer .info .columns {}
#panel .footer .info .legal {    color: #999;font-size: 12px;    margin-bottom: 2px;}
#panel .footer .info .copyRight {    font-size: 12px;color: #888;display: block;}
#panel .footer .info .agency {}
#panel .footer .info .agency img {    width: 58px;margin-left: -4px;}
#panel .footer .content {    margin-left: 40px;margin-right:10px;max-width: 580px;}
#panel .footer .columns.shrink .content {text-align:right}

#sideMenu {height:100%;padding:0px;width:42px;}
#sideMenu ul {list-style:none;margin:0px;    padding: 0px;}
#sideMenu ul li {position:relative;width: 42px;height: 40px;text-align: center;font-size: 20px;color:#999;padding: 3px 0px;border-left: solid 2px transparent;padding-right:1px;border-top-left-radius: 4px;border-bottom-left-radius: 4px;transition: all .1s ease;}
#sideMenu ul li > span {display:block;}
#sideMenu ul li > span a {display: block;height: 34px;    padding-top: 1px;font-size:13px;}
#sideMenu ul li:first-child {margin-top:12px}
#sideMenu ul li svg {    margin-top: 7px;color:#AAA}
#sideMenu ul li.active {border-left-color:#2BA6CB;color:#555 !important;;background-color: #FFF !important;;cursor:default !important;box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);}
#sideMenu ul li:hover {cursor:pointer;background-color:#eaeaea;}
#sideMenu ul li.active:hover svg {color:#777;}
#sideMenu ul li.active svg {color:#555 !important;}
#sideMenu ul li.warning span:first-child {    color: #F1C251;}
#sideMenu ul li .bubble {text-align:center;font-size: 9px;position: absolute;right: 4px;bottom: 4px;border-radius: 50%;line-height: 15px;background-color: #ddd;background-color: rgba(221, 221, 221, 0.84);color: #666;width: 15px;height: 15px;}
#sideMenu ul li .bubble[data-count='0'] {display:none;}
#sideMenu ul li .bubble:after {content: attr(data-count);}

#panelContent {padding-top:12px;padding-left:0px;position:relative;}
#panelContent > div:first-child {background-color: #FFF;border-radius: 6px;border-top-left-radius: 0px;position:relative;overflow:hidden;}
#panelContent > div:first-child > div > .header {position:relative;z-index: 1000;text-align:left;padding:0px 0px 8px 1px;}
#panelContent > div:first-child > div > .header .back svg {margin: 0px 10px 0px;}
#panelContent > div:first-child > div > .header > div {margin:0px 8px 0px 10px;border-bottom:solid 1px #f4f4f4;background: #FFF;box-shadow: 0px 1px 0px 0px rgba(0,0,0,0);}
#panelContent > div:first-child > div > .header > div > .row {    padding: 2px 0px 4px 14px;}
#panelContent > div:first-child > div > .header.sticked > div {box-shadow: 0px 1px 0px 0px rgba(0,0,0,0.1);}
#panelContent > div:first-child > div > .header h1 {margin:0px}
#panelContent > div:first-child > div > .header h1 a {font-size: 20px;margin:0px;padding: 0px;color: #666;line-height: 34px;font-weight:500}
#panelContent > div:first-child > div > .header h1 a.last {color: #222;cursor:default;}
#panelContent > div:first-child > div > .header > div {padding:2px 0px 4px 4px;height:40px;}
#panelContent > div:first-child > div > .header h1 {    font-size: 20px;margin-bottom: 0px;padding:0px;color:#666;line-height: 34px;}
#panelContent > div:first-child > div > .header h1 a {    color: #767676;}
#panelContent > div:first-child > div > .header h1 a.back {    margin-left: -8px;position: relative;    margin-right: 2px;}
#panelContent > div:first-child > div > .header h1 a:hover {color: #222;}
#panelContent > div:first-child > div > .header a.button {    margin: 5px -8px 0px 2px;display: inline-block;}
#panelContent > div:first-child > div > .header a.button.disconnect {}
#panelContent > div:first-child > div > .header h1 a img {width: 24px;height: 24px;position: relative;top: -2px;margin-right: 4px;border: solid 1px #EEE;border-radius: 2px;}
#panelContent > div:first-child > div + div + div {    padding-top: 0px;text-align:left;}
#panelContent > div:first-child > .content > div > .content {}
#panelContent > div:first-child > .content > div > .content > div {padding:10px;}

#panelContent .columns.sperated {border-right: solid 1px #f2f2f2;}
#panelContent .wrapContainer {    max-height: 486px;overflow: auto;}
#sidePanel.open>div>div+div>div, #sidePanel.open>div>div+div>div>div, #sidePanel.open>div>div+div>div>div>div {height:100%}
#panelContent .block {background-color: #f7f7f7;border-radius: 4px;border: solid 1px #f4f4f4;box-shadow: 0 2px 4px rgba(0,0,0,0.1);padding:5px 16px;    margin-bottom: 16px;z-index:3}
#panelContent .block.transparent {background:transparent;border:0px;box-shadow:none;    padding: 0px;}
#panelContent .block.noMargin {margin:0px;}
#panelContent .block.marginTop {margin-top:14px}
#panelContent .block b {    font-size: 14px;position:relative;}
#panelContent .block .info {position:absolute;right:6px;}
#panelContent .block b svg {position:absolute;right:-8px;top:4px;color:#999;}
#panelContent .block b svg:hover {color:#666;}
#panelContent .block .header {border:0px;border-bottom: solid 1px #EEE;padding-bottom: 6px;}
#panelContent .block .action.link {display:block;}
#panelContent .block.fixed {height:150px;}
#panelContent .block.noPad {padding:0px;}
#panelContent .block > div > .columns:first-child {border-right: solid 1px #EEE;}
#panelContent .block > div > .columns:first-child + .columns + .columns {border-left: solid 1px #EEE;}
#panelContent .block .group {border-top:solid 1px #e6e6e6}
#panelContent .block .group:first-child {border-top:0px;}
#panelContent .block .group > .row > .columns + .columns {border-left:solid 1px #e6e6e6}
#panelContent .block .container > p {    margin: 6px 0px 9px;font-size: 14px;color: #555;}
#panelContent .block .container > b {    margin-top: 6px;display: block;font-size: 16px;}
#panelContent .block .container > b.small {font-size:14px;margin-top:12px;}
#panelContent .block.menu {    min-width: 250px;background: #FFF;padding: 0px;}
#panelContent .block.menu ul {list-style:none;padding:0px;margin:10px 10px}
#panelContent .block.menu ul li {}
#panelContent .block.menu ul li a {display:block;padding:8px 14px;background-color:#eee;border-right:solid 4px transparent;font-size: 14px;color:#666;border-bottom:solid 1px #e2e2e2;}
#panelContent .block.menu ul li:first-child a {border-top:solid 1px #e2e2e2;}
#panelContent .block.menu ul li:hover a {background-color:#e2e2e2;}
#panelContent .block.menu ul li.active a {background-color:#CCC;border-right-color:#39acce;font-weight:600;cursor:default !important;}
#panelContent .block.menu ul li a svg {margin:0px 10px 0px 0px}

#switch {position:relative;left: 14px;min-width:114px !important;}
#switch .slashes {position:Absolute;left:-15px;top:1px;font-weight:700}
#switch .selected {    border: 1px solid transparent;border-bottom: 0px;border-top:0px;border-bottom-left-radius: 0px;border-bottom-right-radius: 0px;width: 108%;padding-right: 16px;padding-left: 16px;    margin-left: -4px;cursor:default;}
#switch:hover .selected {border-color:#CCC;}
#switch .selected span {font-size: 20px;margin: 0px;padding: 0px;color: #555;line-height: 34px;font-weight: 500;}
#switch .selected .row .columns:first-child {padding:0px 0px 0px 8px}
#switch .selected svg {    margin: 10px -4px;}
#switch ul {display:none;list-style:none;margin:0px;padding:0px;position: absolute;background: #FFF;width: 108%;top: 36px;border: 1px solid #CCC;border-radius: 4px;border-top: 0px;border-top-left-radius: 0px;border-top-right-radius: 0px;overflow:hidden;    margin-left: -4px;}
#switch:hover ul {display:block;}
#switch ul li {    font-size: 14px;padding: 6px 10px 6px;background: #f2f2f2;border-top:solid 1px #DDD;}
#switch ul li:hover {cursor:pointer;background-color:#666;color:#FFF;}
#switch ul li:first-child {border-top:0px;}

#dashboard .block .recent .faces {    margin:6px 10px 8px 0px;    padding: 0px;}
#dashboard .block .recent li {list-style:none;padding: 4px 8px;padding-left: 0px;position:relative;z-index:1;}
#dashboard .block .recent li:hover {z-index:100;}
#dashboard .block .recent .visitor {border-radius:50%;width:30px;height:30px;background-color: #ddd;position:relative;}
#dashboard .block .recent .visitor:before {display:block;content:"";width:100%;height:100%;position:absolute;left:0px;top:0px;background-color:#2ba6cb;transition: all .1s ease;border-radius:50%;}
#dashboard .block .recent .visitor:hover:before {width:109%;height:109%;left: -5%;top: -5%;}
#dashboard .block .recent img {border-radius:50%;display:inherit;position:relative;width: 100%;height: 100%;transform:scale(1);}
#dashboard .block .recent img:hover {transform:scale(3);box-shadow: 0 2px 4px rgba(0,0,0,0.1);}

#dashboard .block .graph .swiper-pagination {    position: absolute;right: -4px;left: auto;top: -2px;width: auto;}
#dashboard .block .graph .swiper-pagination-bullet {width:6px;height:6px;    margin-right: 5px;}
#dashboard .block .graph .swiper-pagination-bullet-active {background:#74c3db;}

#dashboard .block .announcements {font-size: 13px;color: #666;height: 38px;overflow: hidden;}
#dashboard .block .announcements b {font-size:13px;}
#dashboard .block .announcements > div {padding: 10px 12px;}
#dashboard .block .announcements svg {position:relative;top:1px;margin-right:2px}
#dashboard .block .announcements .sperate {display:inline-block;margin: 0px 14px;color: #BBB;}

#dashboard .activity .block {position:relative;}
#dashboard .activity .info {    height: 52px;overflow: hidden;margin-bottom: 12px;padding: 8px 10px;cursor:pointer;}
#dashboard .activity .info svg {color:#999;    margin-top: 6px;margin-left:4px}
#dashboard .activity .info .more svg {    border: solid 1px #ddd;border-radius: 4px;padding: 5px 10px;    margin-top: 0px;transition: all .2s ease;}
#dashboard .activity .info:hover .more svg {background-color:#666;color:#FFF;}
#dashboard .activity .info p {margin:0px;color:#666;font-size:12px;line-height:14px;margin-left:10px;    margin-top: 3px;}
#dashboard .activity .info p a {display:block;font-size:12px;}
#dashboard .activity .block .container {position:Relative;overflow: hidden;}
#dashboard .activity .block .line {position:Absolute;left:56px;top:24px;height:100%;width:3px;background-color:#e6e6e6;border:0px;}
#dashboard .activity .block ul {margin:0;padding:0;margin-top: 16px;}
#dashboard .activity .block ul li {}
#dashboard .activity .block ul li .columns {padding:0px 6px}
#dashboard .activity .block ul li .columns:first-child {padding-left:0px;}
#dashboard .activity .block ul li .image {    width: 36px;border-radius: 50%;border: 2px solid #FFF;background-color: #ddd;}
#dashboard .activity .block ul li .icon svg {width: 21px;height: 20px;padding: 5px;border-radius: 50%;position:relative;z-index:2;color: #777;margin: 0px;left: -5px;top: -5px;background: #c8fb2a;}
#dashboard .activity .block ul li .detail {    margin-left: 4px;}
#dashboard .activity .block ul li .detail .fullName {    font-size: 14px;font-weight: 700;color: #555;margin-top:2px;}
#dashboard .activity .block ul li .detail .date {font-size: 12px;color: #999;}
#dashboard .activity .block ul li .detail .description {font-size: 12px;margin: 6px 0px 24px;background: #eee;padding: 5px 9px;border-radius: 6px;}

#dashboard ul.streaming {max-height: 600px;overflow: auto;}

#dashboard .block .progress {margin-right: 20px;}
#dashboard .block .user .progress {    margin-top: -16px;}
#dashboard .block .progress .count {    font-size: 32px;line-height: 32px;margin:8px 2px -5px;font-weight: 600;color: #444;position: relative;}
#dashboard .block .progress .count small {font-size: 12px;margin-left: 8px;color: #999;    position: absolute;right: 0px;top: 12px;}
#dashboard .user .progress .count {font-size:14px;line-height:initial;}
#dashboard .block .progress p {font-size: 10px;margin: 0px;color: #666;}

#dashboard .block .list {}
#dashboard .block .list .title {width:182px}
#dashboard .block .list ul {margin:0px;padding:0px;}
#dashboard .block .list ul li.user {    padding: 10px 0px;border-top: solid 1px #EEE;}
#dashboard .block .list ul li.user:first-child {border-top:0px;}
#dashboard .block .list ul li.user b {font-size:14px;color:#555}
#dashboard .block .list ul li.user .name > .row {padding: 0px 0px 0px 45px;}
#dashboard .block .list ul li.user .name > .row small {margin-bottom:6px;}
#dashboard .block .list ul li.user .name > .row small.percent {margin-top:-16px}
#dashboard .block .list ul li.user .name img {border-radius:50%;display:inherit;position:relative;transform:scale(1);    width: 30px;height: 30px;margin:4px 10px 0px 0px;}
#dashboard .block .list ul li.user .name img:hover {transform:scale(3);box-shadow: 0 2px 4px rgba(0,0,0,0.1);}
#dashboard .block .list ul li.user .name b {display:block;}
#dashboard .block .list ul li.user .name small {display:block;color:#999;    line-height: 14px;}
#dashboard .block .list .faces {margin:0px;padding: 0px;}
#dashboard .block .list .faces li {list-style:none;padding: 4px 8px;padding-left: 0px;position:relative;z-index:1;    width: 18px;}
#dashboard .block .list .faces li:hover {z-index:100;}
#dashboard .block .list .faces .visitor {border-radius:50%;width:30px;height:30px;background-color: #ddd;position:relative;}
#dashboard .block .list .faces .visitor:before {display:block;content:"";width:100%;height:100%;position:absolute;left:0px;top:0px;background-color:#2ba6cb;transition: all .1s ease;border-radius:50%;}
#dashboard .block .list .faces .visitor:hover:before {width:109%;height:109%;left: -5%;top: -5%;}
#dashboard .block .list .faces img {border-radius:50%;display:inherit;position:relative;width: 100%;height: 100%;transform:scale(1);width: 30px;height: 30px;}
#dashboard .block .list .faces img:hover {transform:scale(3);box-shadow: 0 2px 4px rgba(0,0,0,0.1);}

#projects .block .list {}
#projects .block .list .title {width:182px}
#projects .block .list .projectName {width:230px}
#projects .block .list .projectSteps {width:200px}
#projects .block .list .projectName > .title {width:auto;margin:0px}
#projects .block .list .stepper {padding-left:36px;}
#projects .block .list .actions {}
#projects .block .list .actions .action {font-size: 18px;border: solid 1px #ccc;color: #aaa;background-color: transparent;padding: 4px 10px 6px;border-radius: 4px;margin: 0px 0px 0px;transition: all .2s ease;display:inline-block}
#projects .block .list .actions .action:hover {    background-color: #666;color: #FFF;border-color: #666;cursor: pointer;}
#projects .block .list .actions .action svg {    width: 14px;position: relative;top: -1px;}
#projects .block .list .item > .main {margin: 0px 0px;border: solid 1px transparent;border-bottom: 0px;border-top:0px;padding-bottom: 10px;padding-top: 10px;}
#projects .block .list .item.open > .main {background: #fff;border-color:#EEE}
#projects .block .list .item .dropDown {display:none;    height: 200px;background: #fefefe;padding: 14px 20px;border: solid 1px #eee;}
#projects .block .list .item.open .dropDown {display:block;}
#projects .block .list ul li.user .button {margin-left:6px}
#projects .block .list .item .arrow {    -webkit-transform: rotate(0);transform: rotate(0);cursor:pointer;}
#projects .block .list .item.open .arrow {-webkit-transform: rotate(180deg);transform: rotate(180deg);}
#projects .block .list ul li.user .button.icon {position: relative;font-size: 15px;padding-top: 7px;padding-bottom: 7px;padding: 6px 8px 8px;}
#projects .block .list ul li.user .button.red {color: #960c0c;border-color: #960c0c;}
#projects .block .list ul li.user .button.red:hover {background-color:#960c0c;color:#FFF;}
#projects .block .list ul li.user .button.yellow {color: #c39900;border-color: #c39900;}
#projects .block .list ul li.user .button.yellow:hover {background-color:#c39900;color:#FFF;}
#projects .block .list ul li.user .button.blue {color: #4085c7;border-color: #4085c7;}
#projects .block .list ul li.user .button.blue:hover {background-color:#4085c7;color:#FFF;}
#projects .block .list .item .groupName {font-weight:700;font-size:16px;margin-bottom:10px}
#projects .block .list .item .groupName + div {    border: solid 1px #EEE;}
#projects .block .list .item .groupItem {border-top:solid 1px #EEE;    background: #fff;padding: 6px 16px;    font-size: 14px;}
#projects .block .list .item .groupItem:first-child {border-top:0px}
#projects .block .list .item .groupItem svg {height:26px}
#projects .block .list .item .groupItem:hover {background-color:#666;color:#FFF;cursor:pointer;}
#projects .block .list .item .arrow {    -webkit-transform: rotate(0);transform: rotate(0);cursor:pointer;}
#projects .block .list .item.open .arrow {-webkit-transform: rotate(180deg);transform: rotate(180deg);}
#projects .block .list ul {margin:0px;padding:0px;}
#projects .block .list ul li.user {margin:0px;list-style:none;border-top: solid 1px #EEE;padding: 10px 0px;}
#projects .block .list ul li.user:first-child {border-top:0px;}
#projects .block .list ul li.user b {font-size:14px;color:#555}
#projects .block .list ul li.user .name {}
#projects .block .list ul li.user .name img {border-radius:50%;display:inherit;position:relative;transform:scale(1);    width: 30px;height: 30px;margin:4px 10px 0px 0px;}
#projects .block .list ul li.user .name img:hover {transform:scale(3);box-shadow: 0 2px 4px rgba(0,0,0,0.1);}
#projects .block .list ul li.user .name b {display:block;}
#projects .block .list ul li.user .name small {display:block;color:#999;    line-height: 14px;}
#projects .block .list .container > ul{min-height:440px}
#projects .block .list .faces {margin:0px;padding: 0px;    margin-left: 10px;}
#projects .block .list .faces li {list-style:none;padding: 4px 8px;padding-left: 0px;position:relative;z-index:1;    width: 18px;}
#projects .block .list .faces li:hover {z-index:100;}
#projects .block .list .faces .visitor {border-radius:50%;width:30px;height:30px;background-color: #ddd;position:relative;}
#projects .block .list .faces .visitor:before {display:block;content:"";width:100%;height:100%;position:absolute;left:0px;top:0px;background-color:#2ba6cb;transition: all .1s ease;border-radius:50%;}
#projects .block .list .faces .visitor:hover:before {width:109%;height:109%;left: -5%;top: -5%;}
#projects .block .list .faces img {border-radius:50%;display:inherit;position:relative;width: 100%;height: 100%;transform:scale(1);width: 30px;height: 30px;}
#projects .block .list .faces img:hover {transform:scale(3);box-shadow: 0 2px 4px rgba(0,0,0,0.1);}
#projects .block .list .shimmer  svg  {height:30px;}
#projects .shimmer {    padding: 22px 0px 0px;}

#users .block .list {}
#users .block .list .title {width:160px}
#users .block .list ul {margin:0px;padding:0px;}
#users .block .list ul li.user {    padding: 10px 0px;border-top: solid 1px #EEE;}
#users .block .list ul li.user:first-child {border-top:0px;}
#users .block .list ul li.user b {font-size:14px;color:#555}
#users .block .list ul li.user .name {}
#users .block .list ul li.user .name img {border-radius:50%;display:inherit;position:relative;transform:scale(1);    width: 30px;height: 30px;margin:4px 10px 0px 0px;}
#users .block .list ul li.user .name img:hover {transform:scale(3);box-shadow: 0 2px 4px rgba(0,0,0,0.1);}
#users .block .list ul li.user .name b {display:block;}
#users .block .list ul li.user .name small {display:block;color:#999;    line-height: 14px;}
#users .block .list ul li.user .activities {font-size:12px;font-weight:700}
#users .block .list ul li.user .activities > div {line-height:initial}
#users .block .list ul li.user .activities small {font-weight:400;color:#666}
#users .block .list ul li.user .switch {    margin-top: 9px;}


#filter {    padding:4px 8px 6px !important;position:relative;}
#filter [role='button'] {outline:0}
#filter .row {border:0px !important;}
#filter select {    margin: 0px 0px 0px;    min-width: 170px;max-width:196px;}
#filter select.small {min-width:100px;max-width:170px;}
#filter select.medium {max-width: 160px;min-width: 150px;}
#filter input[type='text'] {margin-bottom:0px;border: solid 1px #c5c5c5;padding: 9px;border-radius: 4px;font-size: 16px;}
#filter input[type='text'].small {    min-width: 80px;max-width: 170px;}
#filter .inputHeader {    font-size: 12px;color: #888;margin-bottom: 4px;}
#filter .export {    font-size: 18px;border: solid 1px #ccc;color: #aaa;background-color:transparent;padding: 4px 10px;border-radius: 4px;margin: 22px 0px 0px;transition: all .2s ease;}
#filter .export:hover {background-color:#666;color:#FFF;border-color:#666;cursor:pointer;}
#filter .export:hover svg {color:#FFF;}

#tabs {padding:0px;margin-left: -1px;}
#tabs + .columns {margin-top:0px !important;border-top-left-radius: 0px !important;}
#tabs ul {list-style:none;padding:0px;    margin-bottom: 0px;position:relative;top:1px;z-index:2;}
#tabs li {}
#tabs a {    display: block;padding: 8px 16px;background: #e6e6e6;font-weight: 600;border-left:solid 1px #eee;}
#tabs li:first-child a {border-top-left-radius:4px;border-left:0px}
#tabs li:last-child a {border-top-right-radius:4px;}
#tabs a.active {background-color:#f7f7f7;color:#12566b;cursor:default;}
#tabs .button-icon {    font-size: 18px;border: solid 1px #ccc;color: #aaa;background-color:transparent;padding: 0px 5px;;border-radius: 4px;margin:0px;transition: all .2s ease;    line-height: 22px;    height: 26px;}
#tabs .button-icon svg {    font-size: 14px;}
#tabs .button-icon:hover {background-color:#666;color:#FFF;border-color:#666;cursor:pointer;}
#tabs .button-icon:hover svg {color:#FFF;}

#datePicker {position:relative;}
#datePicker .values {    font-size: 14px;border: solid 1px #CCC;border-radius: 4px;padding: 1px 0px 1px 5px;background-color:#FFF;    width: 250px;cursor:pointer;}
#datePicker.active .values {position: relative;z-index: 2;border-bottom: 0px;border-bottom-left-radius:0;border-bottom-right-radius:0px;    background: #eff2f7;cursor:default;}
#datePicker .values span {    margin: 6px 4px;display: inline-block;}
#datePicker .values.multiple span {margin:4px 4px 0px}
#datePicker .values .sub {line-height: 8px;}
#datePicker .values .sub span {font-size:9px;color:#666;margin:0px 4px 4px;}
#datePicker .values .sperator {    margin: 0px !important;margin-left: 1px !important;}
#datePicker .values svg {display: inline-block;margin-left: 8px;color: #CCC;position: relative;top: 6px;border-left: solid 1px #CCC;width: 36px;cursor:pointer;}
#datePicker .values svg path {transition: color .2s ease-out 0s;}
#datePicker.active .values svg path {    transform: rotate(180deg);transform-origin: 10px;}
#datePicker:hover .values svg path {color: #888;}
#datePicker .dropDown {display:none;position:absolute;right:0px;top:35px;border: solid 1px #CCC;border-radius: 6px;overflow: hidden;border-top-right-radius: 0px;    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);}
#datePicker .values.multiple + .dropDown {top:41px;}
#datePicker.active .dropDown {display:block;}
#datePicker .rdrMonthAndYearWrapper {    height: 40px;padding-bottom: 8px;}
#datePicker .rdrStaticRangeLabel {padding:8px 20px}
#datePicker  .rdrMonth {padding:0 0.833em 0.666em 0.833em;}
#datePicker .rdrDateDisplay:first-child {    padding-bottom: 0px;}

#projects .stepper {    position: relative;top: 2px;}
#projects .stepper span,#projects .stepper a {cursor:pointer;}
#projects .stepper a:first-child,#projects .stepper span:first-child {position: relative;top: -2px;}

#projects .block .progress {margin-right: 0px;}
#projects .block .progress .action.link {margin-bottom: 10px;}
#projects .block .progress .count {    font-size: 32px;line-height: 32px;margin:8px 2px -5px;font-weight: 600;color: #444;position: relative;}
#projects .block .progress .count small {font-size: 12px;margin-left: 8px;color: #999;    position: absolute;right: 0px;top: 12px;}
#projects .block.info .chart {    padding: 0px 0px 14px;}

#analytics {}
#analytics .summary {}
#analytics .insight {margin-bottom: 5px;border-left:solid 1px #DDD;}
#analytics .insight:first-child {border-left:0px;    margin-left: 3px;}
#analytics .insight > div {margin: 0px 3px;width:93px}
#analytics .summary .value {    height: 38px;}
#analytics .summary .value .number {font-size: 30px;font-weight: 600;color:#555;}
#analytics .summary .value svg {    margin:20px 6px 0px 8px;color:#5a9ab7}
#analytics .summary .value.positive svg {color:#e38680}
#analytics .summary .value .percent {    margin-top: 18px;display: block;font-size: 14px;color:#5a9ab7}
#analytics .summary .value.positive .percent {color:#e38680;}
#analytics .summary .result {}
#analytics .summary .result .text {    font-size: 12px;color:#999}
#analytics .summary .result svg {margin-top:0px;color:#999;}
#analytics .summary .result svg:hover {color:#555;}

#analytics .block .progress {margin-right: 0px;}
#analytics .block .progress .action.link {margin-bottom: 10px;}
#analytics .block .progress .count {    font-size: 32px;line-height: 32px;margin:8px 2px -5px;font-weight: 600;color: #444;position: relative;}
#analytics .block .progress .count small {font-size: 12px;margin-left: 8px;color: #999;    position: absolute;right: 0px;top: 12px;}
#analytics .block.info .chart {    padding: 0px 0px 14px;}

#panelContent .block .chart.big {    margin-top: 20px;    margin-left: -8px;}
#panelContent .block .chart.big > div {border:0px !important;}


#activity .block .list {position:relative;}
#activity .block .list .line {    position: absolute;left: 208px;top: 0px;width: 4px;height: 100%;background: #e2e2e2;}
#activity .block .list .title {width:180px}
#activity .block .list .action {}
#activity .block .list .action svg {position:relative;z-index:2;border-radius: 50%;background: #666;padding: 8px;width: 30px;height: 30px;color: #FFF;}

#activity .block .header .name {width:255px}
#activity .block .list .description b {display:block;font-weight: 500;background: #e9e9e9;padding: 8px 12px;border-radius: 4px;margin:2px 0px 10px}
#activity .block .list .description a {    font-size: 14px;}
#activity .block .list .description small {display:block;    line-height: 14px;font-size:12px;color:#666;cursor:default;}
#activity .block .list .description small svg {    font-size: 10px;position: relative;top: -1px;margin-right:2px;}
#activity .block .list ul {margin:0px;padding:0px;position:relative;list-style:none;}
#activity .block .list ul li.user {    padding: 10px 0px;border-top: solid 1px #EEE;}
#activity .block .list ul li.user:first-child,
#activity .block .list ul .line + li.user {border-top:0px;}
#activity .block .list ul li.user b {font-size:14px;color:#555}
#activity .block .list ul li.user .name {width:210px}
#activity .block .list ul li.user .name img {border-radius:50%;display:inherit;position:relative;transform:scale(1);    width: 30px;height: 30px;margin:4px 10px 0px 0px;}
#activity .block .list ul li.user .name img:hover {transform:scale(3);box-shadow: 0 2px 4px rgba(0,0,0,0.1);}
#activity .block .list ul li.user .name b {display:block;}
#activity .block .list ul li.user .name small {display:block;color:#999;    line-height: 14px;}
#activity .block.filter {}
#activity .block.filter .title {display:block;line-height: 14px;}
#activity .block.filter ul {padding:0px;list-style:none;}
#activity .block.filter ul li {    padding: 12px 0px;border-top:solid 1px #f2f2f2;cursor:default;transition: all .2s ease-out 0s;}
#activity .block.filter ul li:first-child {border-top:0px}
#activity .block.filter ul li:hover {background: #f1f1f1;}
#activity .block.filter ul li .status {    line-height: 20px;font-size:12px;color:#ff4500;}
#activity .block.filter ul li.active .status {color:#006400}
#activity .block.filter ul li .status b {font-size:12px;color:#666;line-height: 20px;}
#activity .block.filter svg {position:relative;z-index:2;border-radius: 50%;background: #666;padding: 8px;width: 30px;height: 30px;color: #FFF;transition: all .2s ease-out 0s;}
#activity .block.filter .switch {margin:5px 16px 0px;cursor:pointer}

#settings .stickyWrapper {    min-width: 250px;}
#settings .fieldGroup {border-bottom:solid 1px #EEE;    padding: 8px 0px;}
#settings .field {border-bottom:solid 1px #EEE;    padding: 6px 0px;}
#settings .fieldGroup > div > .field {border-bottom:0px;}
#settings .fieldGroup > div:first-child > .field {border-right:solid 1px #EEE;}
#settings .field .title {line-height: initial;font-weight: bold;color: #555;font-size: 14px;}
#settings .field .description {margin: 0px;margin-bottom: 8px;color: #888;font-size: 12px;line-height: initial;margin-top: 2px;}
#settings .field .switchWrapper {margin-right:5px;}
#settings .field .switchWrapper .text {    display: inline-block;padding-right: 6px;font-size: 12px;color: #666;padding-top:0px}
#settings .field .switchWrapper .input {}
#settings .field .inputWrapper > div {    margin-bottom: 16px;}
#settings .field .inputWrapper > div > input {width:100%;padding:9px 10px;    border: solid 1px #e2e2e2;}
#settings .field .inputWrapper > div > input + span {top: 42px !important;left: 4px !important;font-size: 9px !important;color: #999;}
